import {
  createMemo,
  createUniqueId,
  Match,
  onMount,
  Show,
  Switch,
} from "solid-js";
import { useViewportSizeContext } from "~/contexts/ViewportSizeContext";
import { useDrupalSettingsContext } from "~/contexts/DrupalSettingsContext";
import imgProxy from "~/utils/imgproxy";
import { Portal } from "solid-js/web";
import SearchFormHome from "~/components/Search/SearchFormHome";

import type { HomeCampaign } from "~/types/drupal_jsonapi";

import "~/components/shared/CommercialOffer.css";
import "./Campaign.css";
import { useSearchContext } from "~/contexts/SearchContext";

import CogedimSimpleLogo from "~/img/logos/cogedim-simple-logo.svg";
import WPILogo from "~/img/logos/wpi-logo.svg";

export default function Campaign(props: {
  campaign: HomeCampaign;
  home_search_title_color: string;
  home_search_bg_color: string;
}) {
  const settingsContext = useDrupalSettingsContext();

  return (
    <>
      <section class="campaign" data-test="first-screen">
        {/* VISUAL */}
        <CampaignVisual
          image_mobile={props.campaign.image_mobile}
          image_desktop={props.campaign.image_desktop}
        />

        {/* VIDEO */}
        <CampaignVideo
          video_desktop_mp4={props.campaign.video_desktop_mp4}
          video_desktop_webm={props.campaign.video_desktop_webm}
          video_mobile_mp4={props.campaign.video_mobile_mp4}
          video_mobile_webm={props.campaign.video_mobile_webm}
        />

        <div
          class="bottom-part"
          classList={{
            "with-logos": settingsContext.nouveau_neuf_enabled,
          }} /* refs #9080 */
        >
          {/* BASELINE */}
          <Show
            when={settingsContext.nouveau_neuf_enabled && props.campaign.search}
            fallback={
              <DefaultBaseline
                initialLotsCount={props.campaign.lots_count}
                titleColor={props.home_search_title_color}
                searchText={props.campaign.search}
              />
            }
          >
            {/* Add WPI logos refs #9080 */}
            <NNBaseline searchText={props.campaign.search} />
          </Show>

          <div
            style={
              props.home_search_bg_color
                ? `color: ${props.home_search_bg_color}`
                : ""
            }
          >
            <SearchFormHome
              enableLocation={true}
              enableRooms={true}
              enableBudget={true}
              enableMarketingSegments={settingsContext.nouveau_neuf_enabled}
              enableRegulations={true}
              enableLotTypes={true}
              enableSaleStates={true}
              enableAdvancedSearch={true}
              enableSearchButton={true}
              enableCloseButton={true}
            />
          </div>
        </div>
      </section>

      {/* FRONT COMMERCIAL OFFER */}
      <FrontCommercialOffer campaign={props.campaign} />
    </>
  );
}

function CampaignVisual(props: {
  image_mobile: string;
  image_desktop: string;
}) {
  const [viewPortState] = useViewportSizeContext();

  const defaultImg = createMemo(() => {
    return viewPortState.viewPortIsMoreThan768
      ? props.image_desktop
      : props.image_mobile;
  });

  return (
    <>
      <picture class="main-visual" data-test="main-image">
        <source
          media="(min-width: 1367px)"
          srcset={imgProxy(
            props.image_desktop,
            "size:1920:1080/resizing_type:fill",
          )}
        />
        <source
          media="(min-width: 1025px) and (max-width: 1366px)"
          srcset={imgProxy(
            props.image_desktop,
            "size:1366:768/resizing_type:fill",
          )}
        />
        <source
          media="(min-width: 768px) and (max-width: 1024px)"
          srcset={imgProxy(
            props.image_desktop,
            "size:1024:576/resizing_type:fill",
          )}
        />
        <source
          media="(min-width: 376px)"
          srcset={imgProxy(
            props.image_mobile,
            "size:750:1334/resizing_type:fill",
          )}
        />
        <source
          media="(max-width: 375px)"
          srcset={imgProxy(
            props.image_mobile,
            "size:375:667/resizing_type:fill",
          )}
        />
        <img
          fetchpriority="high"
          src={imgProxy(
            defaultImg()!,
            `size:${viewPortState.viewPortIsMoreThan768 ? "1366:768" : "375:667"}/resizing_type:fill`,
          )}
          alt=""
          height={viewPortState.viewPortIsMoreThan768 ? 768 : 667}
          width={viewPortState.viewPortIsMoreThan768 ? 1366 : 375}
        />
      </picture>
    </>
  );
}

function CampaignVideo(props: {
  video_desktop_mp4?: string;
  video_desktop_webm?: string;
  video_mobile_mp4?: string;
  video_mobile_webm?: string;
}) {
  const [viewportSizeProvider] = useViewportSizeContext();

  return (
    <>
      <Show when={props.video_desktop_mp4 || props.video_desktop_webm}>
        <div class="video-container" data-test="video">
          <Show when={viewportSizeProvider.viewPortIsMoreThan768}>
            <VideoTag
              srcMp4={props.video_desktop_mp4}
              srcWebm={props.video_desktop_webm}
              device="desktop"
            />
          </Show>
          <Show when={props.video_mobile_mp4 || props.video_mobile_webm}>
            <Show when={!viewportSizeProvider.viewPortIsMoreThan768}>
              <VideoTag
                srcMp4={props.video_mobile_mp4}
                srcWebm={props.video_mobile_webm}
                device="mobile"
              />
            </Show>
          </Show>
        </div>
      </Show>
    </>
  );
}

function VideoTag(props: {
  srcMp4?: string;
  srcWebm?: string;
  device: string;
}) {
  const playerId = createUniqueId();

  onMount(() => {
    if (document.getElementById(playerId)) {
      const player = document.getElementById(playerId) as HTMLVideoElement;
      player!.muted = true;
      player!.play();
      player.addEventListener("ended", function () {
        player.style.display = "none";
      });
    }
  });

  return (
    <video id={playerId} muted playsinline class="video">
      <Show when={props.srcMp4}>
        <source src={props.srcMp4} type="video/mp4" />
      </Show>
      <Show when={props.srcWebm}>
        <source src={props.srcWebm} type="video/webm" />
      </Show>
    </video>
  );
}

function NNBaseline(props: { searchText?: string }) {
  return (
    <>
      {/*
      <div class="nouveau-neuf-baseline primary">
        <h1>{props.searchText}</h1>
      </div>
      */}

      <div class="baseline-with-logos">
        <div class="inner">
          <h1>{props.searchText}</h1>
          <div class="logos">
            <CogedimSimpleLogo />
            <WPILogo />
          </div>
        </div>
      </div>
    </>
  );
}

function DefaultBaseline(props: {
  initialLotsCount: number;
  titleColor: string;
  searchText?: string;
}) {
  const [searhState] = useSearchContext();
  return (
    <>
      <div
        class="h1"
        style={props.titleColor ? `color: ${props.titleColor}` : ""}
      >
        <div>
          <Switch
            fallback={
              <h1>
                Des maisons <span class="br">et appartements neufs</span>
              </h1>
            }
          >
            <Match when={!searhState.counts}>
              {new Intl.NumberFormat("fr-FR", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }).format(props.initialLotsCount)}{" "}
              <h1>
                maisons <span class="br">et appartements neufs</span>
              </h1>
            </Match>
            <Match when={searhState.counts && searhState.counts!.count > 0}>
              {new Intl.NumberFormat("fr-FR", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }).format(searhState.counts!.count)}{" "}
              <h1>
                maisons <span class="br">et appartements neufs</span>
              </h1>
            </Match>
            <Match
              when={searhState.counts && searhState.counts!.count_proximity > 0}
            >
              {new Intl.NumberFormat("fr-FR", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }).format(searhState.counts!.count_proximity)}{" "}
              <h1>
                maisons <span class="br">et appartements neufs</span>
              </h1>
            </Match>
          </Switch>
        </div>
        <Show when={props.searchText}>
          {" "}
          {props.searchText}
          <br />
        </Show>
      </div>
    </>
  );
}

function FrontCommercialOffer(props: { campaign: HomeCampaign }) {
  const settingsContext = useDrupalSettingsContext();

  return (
    <>
      <Switch>
        <Match when={settingsContext.nouveau_neuf_enabled}>
          <section
            class="commercial-part with-nouveau-neuf"
            data-test="animation"
          >
            <div
              class="animation-commercial"
              innerHTML={props.campaign.markup}
            />
          </section>
        </Match>
        <Match when={props.campaign.title}>
          <section class="commercial-part">
            <p>
              <strong innerHTML={props.campaign.title} />
            </p>
          </section>
        </Match>
      </Switch>
      <Portal mount={document.getElementById("legals-offer-receiver")!}>
        <Show when={props.campaign.legals} keyed>
          <p
            class="legals"
            data-test="legals-offer"
            innerHTML={props.campaign.legals}
          />
        </Show>
      </Portal>
    </>
  );
}
