import Campaign from "~/components/Home/Components/Campaign";
import type { HomeData } from "~/types/drupal_jsonapi";

import "./FrontPage.css";
import { AutopromosRenderer } from "~/components/shared/Autopromos/AutopromosRenderer";
import FrontLandings from "~/components/Home/Components/FrontLandings";
import FrontPromotedPrograms from "~/components/Home/Components/FrontPromotedPrograms";
import FrontArticles from "~/components/Home/Components/FrontArticles";
import FrontCogedimAndYou from "~/components/Home/Components/FrontCogedimAndYou";
import FrontPlot from "~/components/Home/Components/FrontPlot";
import { useDrupalSettingsContext } from "~/contexts/DrupalSettingsContext";
import { Show, Suspense } from "solid-js";
import FrontPromoEscda from "~/components/Home/Components/FrontPromoEscda";
import { useLotActive } from "~/contexts/LotActiveContext";
import LotDrawer from "~/components/Program/Components/LotDrawer";

export default function FrontPage(props: { data: HomeData }) {
  const settings = useDrupalSettingsContext();
  const [lotProvider] = useLotActive();

  return (
    <>
      <article class="front-page">
        <Show when={lotProvider.wrapper && lotProvider.lot} keyed>
          <Suspense>
            <LotDrawer
              wrapper={lotProvider.wrapper!}
              lot={lotProvider.lot!}
              isNotFromProgram
            />
          </Suspense>
        </Show>

        <Campaign
          campaign={props.data.campaign}
          home_search_title_color={props.data.home_search_title_color}
          home_search_bg_color={props.data.home_search_bg_color}
        />

        {/* AUTOPROMO AREAS */}
        <section class="front-areas-container">
          <AutopromosRenderer text="[promo-areas]" />
          <Show when={settings.escda_enabled}>
            <FrontPromoEscda />
          </Show>
        </section>

        {/* LANDINGS */}
        <FrontLandings />

        {/* PTZ 2024 */}
        <AutopromosRenderer text="[promo-ptz-2024-home]" />

        {/* RESIDENCES A LA UNE */}
        <Show when={props.data.programs.length}>
          <FrontPromotedPrograms programs={props.data.programs} />
        </Show>

        {/* ARTICLES */}
        <FrontArticles
          articles={props.data.articles}
          title="<strong>Les clés</strong> pour bien acheter"
        />

        {/* COGEDIM & VOUS */}
        <FrontCogedimAndYou faqs={props.data.faqs} />
      </article>

      {/* PLOT */}
      <FrontPlot />
    </>
  );
}
