import { urlRs } from "~/utils/url";
import { useEventsContext } from "~/contexts/EventsContext";
import imgProxy from "~/utils/imgproxy";
import { Match, Switch } from "solid-js";

import "./FrontLandings.css";

import IconLocationOn from "~/img/icons/location_on.svg";
import IconNotificationsOutline from "~/img/icons/notifications_outline.svg";
import IconKeyOutline from "~/img/icons/key_outline.svg";
import IconDirectionsCar from "~/img/icons/directions_car.svg";
import IconAccountBalanceWalletOutline from "~/img/icons/account_balance_wallet_outline.svg";
import IconChairOutline from "~/img/icons/chair_outline.svg";
import IconPercent from "~/img/icons/percent.svg";

export default function FrontLandings() {
  return (
    <>
      <section class="front-landings" data-test="landings">
        <div class="landings">
          <FrontLanding
            title="Avant-première"
            url="/votre-futur-bien/par-opportunite/avant-premieres/"
            image="/images/front-page/landing-avant-premiere-2.jpg"
            icon="notifications"
          />
          <FrontLanding
            title="Prêt à emménager"
            url="/votre-futur-bien/par-opportunite/livraisons-imminentes/"
            image="/images/front-page/landing-pret-a-emmenager-2.jpg"
            icon="key"
          />
          <FrontLanding
            title="Parking"
            url="/investir/parkings/"
            image="/images/front-page/landing-parkings.jpg"
            icon="car"
            isParkings
          />
          <FrontLanding
            title="Éligible LMNP"
            url="/votre-futur-bien/pour-investir/residences-services/"
            image="/images/front-page/landing-lmnp-2.jpg"
            icon="chair"
          />
          <FrontLanding
            title="Nue-propriété"
            url="/votre-futur-bien/pour-investir/residences-nue-propriete/"
            image="/images/front-page/landing-pinel-2.jpg"
            icon="wallet"
          />
          {/*
          <FrontLanding
            title="TVA réduite 5,5 %"
            url="/votre-futur-bien/par-opportunite/nos-programmes-tva-reduite/"
            image="/images/front-page/landing-tva-reduite-2.jpg"
            icon="percent"
          />
          */}
        </div>
      </section>
    </>
  );
}

type FrontLandingsProps = {
  title: string;
  url: string;
  image: string;
  icon: string;
  isParkings?: boolean;
};

function FrontLanding(props: FrontLandingsProps) {
  const [, { sendClick }] = useEventsContext();

  return (
    <>
      <div class="landing" data-test="card-landing">
        <div class="visual-part">
          <picture class="visual">
            <img
              src={imgProxy(props.image, `size:400:225/resizing_type:fill`)}
              alt=""
              height="180"
              width="280"
              loading="lazy"
            />
          </picture>
          <span class="icon">
            <i aria-hidden="true" class="cog-icon location">
              <IconLocationOn />
            </i>
            <i aria-hidden="true" class="cog-icon svg-icon">
              <Switch>
                <Match when={props.icon === "notifications"}>
                  <IconNotificationsOutline />
                </Match>
                <Match when={props.icon === "key"}>
                  <IconKeyOutline />
                </Match>
                <Match when={props.icon === "car"}>
                  <IconDirectionsCar />
                </Match>
                <Match when={props.icon === "wallet"}>
                  <IconAccountBalanceWalletOutline />
                </Match>
                <Match when={props.icon === "chair"}>
                  <IconChairOutline />
                </Match>
                <Match when={props.icon === "percent"}>
                  <IconPercent />
                </Match>
              </Switch>
            </i>
          </span>
        </div>
        <h3>{props.title}</h3>
        <a
          href={urlRs("landings", props.url)}
          onClick={() => sendClick("btn-landing", "landings")}
          class="link"
          data-test="content-link"
        >
          <span>Découvrir</span>
        </a>
      </div>
    </>
  );
}
