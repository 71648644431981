import type { RouteDefinition } from "@solidjs/router";
import { createAsync } from "@solidjs/router";
import { getHome } from "~/lib/fetcher";
import { Show, Suspense } from "solid-js";
import { Title } from "@solidjs/meta";
import { MetaTags } from "~/components/shared/MetaTags";
import { useEventsContext } from "~/contexts/EventsContext";
import FrontPage from "~/components/Home/FrontPage";
import GtmVars from "~/components/shared/Trackers/GtmVars";

export const route = {
  load() {
    void getHome();
  },
} satisfies RouteDefinition;

export default function IndexRoute() {
  const data = createAsync(() => getHome(), {
    name: "home",
    deferStream: true,
  });

  const [, { setTemplate }] = useEventsContext();
  setTemplate("home");

  const metaDesc = {
    tag: "meta",
    attributes: {
      name: "description",
      content:
        "Construisez le projet qui vous ressemble avec un promoteur de référence, pour habiter ou investir dans l'immobilier neuf. Vous verrez la différence.",
    },
  };

  const gtmValues = {
    "all.pageType": "homepage",
    "all.mainCategory": "home",
    "all.category": "Non défini",
    "all.subCategory": "Non défini",
    "all.subsubCategory": "Non défini",
    "all.subsubsubCategory": "Non défini",
    template: "home",
  };

  return (
    <>
      <Suspense>
        <Show when={data()}>
          <GtmVars values={gtmValues} />
          <Title>Immobilier neuf : votre logement neuf avec Cogedim</Title>
          <MetaTags tags={[metaDesc]} />
          <FrontPage data={data()!} />
        </Show>
      </Suspense>
    </>
  );
}
